import { VendorBcna } from "@myjourney/shared";
import { MergedArticle } from "myjourney-frontend/src/vendor/umbraco";
import * as React from "react";
import { PatientContext } from "../providers/PatientProvider";
import {
	API_PATIENT_LOGIN,
	API_PATIENT_SIGNUP,
	API_PATIENT_PASSWORD_FORGOT,
	API_PATIENT_PASSWORD_RESET,
	API_PATIENT_REFERRAL,
	API_PATIENT_SEARCH,
	API_PATIENT_UPDATE,
} from "../utils/constants";
import Cookies from "js-cookie";

export const usePatientApi = (): {
	patientSignIn: typeof patientSignIn;
	patientRegister: typeof patientRegister;
	patientSearch: typeof patientSearch;
	patientPasswordForgot: typeof patientPasswordForgot;
	patientPasswordReset: typeof patientPasswordReset;
	patientReferral: typeof patientReferral;
	patientUpdate: typeof patientUpdate;
	patientUpdateMetadata: typeof patientUpdateMetadata;
	updateArticleProgress: typeof updateArticleProgress;
} => {
	const { auth, handleAuth, memberMetadata } = React.useContext(PatientContext);

	const patientSignIn = async (data: LoginData) =>
		fetch(`${runtimeconfig.application_domain}${API_PATIENT_LOGIN}`, {
			method: "POST",
			body: JSON.stringify({
				data: {
					patient_credentials: data,
				},
			}),
		}).then(async (response) => {
			const res = await response.json();
			console.log("response when signing in", res);
			return res;
		});

	const patientSearch = async (data: SearchData) =>
		await fetch(`${runtimeconfig.application_domain}${API_PATIENT_SEARCH}`, {
			method: "POST",
			body: JSON.stringify({
				data: {
					search_provider: data.provider,
					search_key: data.key,
					search_value: data.value,
				},
			}),
		});

	const patientRegister = async ({
		patient_credentials,
		patient_referral,
		patient_details,
	}: SignUpData) =>
		await fetch(`${runtimeconfig.application_domain}${API_PATIENT_SIGNUP}`, {
			method: "POST",
			body: JSON.stringify({
				data: {
					patient_credentials,
					patient_referral,
					patient_details,
				},
			}),
		});

	const patientPasswordForgot = async (data: PasswordForgotData) =>
		await fetch(
			`${runtimeconfig.application_domain}${API_PATIENT_PASSWORD_FORGOT}`,
			{
				method: "POST",
				body: JSON.stringify({
					data: {
						forgot_key: data.key,
						forgot_value: data.value,
					},
				}),
			}
		);

	const patientPasswordReset = async (data: PasswordResetData) =>
		await fetch(
			`${runtimeconfig.application_domain}${API_PATIENT_PASSWORD_RESET}`,
			{
				method: "POST",
				body: JSON.stringify({
					data: {
						reset_key: data.key,
						reset_value: data.value,
						password: data.password,
						code: data.code,
					},
				}),
			}
		);

	const patientReferral = async (data: ReferralData) =>
		await fetch(`${runtimeconfig.application_domain}${API_PATIENT_REFERRAL}`, {
			method: "POST",
			body: JSON.stringify({
				data: {
					referral_id: data.referral_id,
				},
			}),
		});

	const patientUpdate = async (updateData: PatientUpdateData) => {
		const patient_details = updateData.patient_details
			? Object.assign(auth?.patient_details, updateData.patient_details)
			: auth?.patient_details;
		const patient_credentials = updateData.patient_credentials
			? Object.assign(auth?.patient_details, updateData.patient_credentials)
			: null;
		const patient_password = updateData.patient_password
			? updateData.patient_password
			: null;
		const patient_preferences = updateData.patient_preferences
			? updateData.patient_preferences
			: null;

		const data = {
			...(patient_details ? { patient_details } : {}),
			...(patient_credentials ? { patient_credentials } : {}),
			...(patient_password ? { patient_password } : {}),
			...(patient_preferences ? { patient_preferences } : {}),
		};
		if (handleAuth) {
			await handleAuth(Object.assign(auth, data));
		}

		const res = await fetch(
			`${runtimeconfig.application_domain}${API_PATIENT_UPDATE}`,
			{
				method: "POST",
				body: JSON.stringify({
					data: {
						application_token: Cookies.get("app_token")
							? Cookies.get("app_token")
							: auth?.application_token,
						...data,
					},
				}),
			}
		);

		return res;
	};

	/** Update the patients metadata */
	const patientUpdateMetadata = async (data: Record<string, unknown>) => {
		const res = await fetch(
			`${runtimeconfig.application_domain}${API_PATIENT_UPDATE}`,
			{
				method: "POST",
				body: JSON.stringify({
					data: {
						application_token: Cookies.get("app_token")
							? Cookies.get("app_token")
							: auth?.application_token,
						patient_preferences: data,
					},
				}),
			}
		);

		const json = await res.json();

		return json;
	};

	/**
	 * Pass an article and a progress to record how much of the article the user has read
	 * @param  {MergedArticle} article
	 * @param  {number} progress
	 */
	const updateArticleProgress = async (
		article: MergedArticle,
		progress: number
	) => {
		const readArticles =
			memberMetadata?.patient_preferences?.readArticles ?? [];
		if (readArticles && article && article.name) {
			const index = readArticles.findIndex((r) => r.id === article.id);
			/**
			 * If the article already exists update it otherwise create it
			 */
			if (index > -1) {
				readArticles[index].progress = progress;
			} else {
				readArticles.push({
					id: article.id,
					name: article.name,
					progress,
					u: Math.round(Date.now() / 1000),
				});
			}
			return await patientUpdateMetadata({
				readArticles,
			});
		}
	};

	return {
		patientSignIn,
		patientSearch,
		patientRegister,
		patientPasswordForgot,
		patientPasswordReset,
		patientReferral,
		patientUpdate,
		patientUpdateMetadata,
		updateArticleProgress,
	};
};

type LoginData =
	| {
		email: string;
		password: string;
	}
	| {
		email: string;
		refresh_token: string;
	};

type PatientUpdateData = {
	patient_details?: Partial<VendorBcna.PatientDetails>;
	patient_credentials?: Partial<VendorBcna.PatientCredentials>;
	patient_password?: {
		password_before: string;
		password_after: string;
	};
	patient_preferences?: Record<string, unknown>;
};

type SignUpData = {
	patient_details: VendorBcna.PatientDetails;
	patient_credentials: VendorBcna.PatientCredentials;
	patient_referral: VendorBcna.PatientReferral;
};

type SearchData = {
	provider: "salesforce" | "koben_identity";
	key: "email" | "phone" | "salesforce_id";
	value: string;
};

type PasswordForgotData = {
	key: "email" | "phone";
	value: string;
};

type PasswordResetData = {
	key: "email" | "phone";
	value: string;
	code: string;
	password: string;
};

type ReferralData = {
	referral_id: string;
};
