import * as React from "react";
import tw, { styled, css } from "twin.macro";

import * as Utils from "~/utils";
import * as Hooks from "~/hooks";
import * as Providers from "~/providers";
import * as Components from "~/components";

import { TemplateDashboardResourceStories } from "./DashboardResourceStories";
import { TemplateDashboardResourceArticles } from "./DashboardResourceArticles";
import { TemplateDashboardResourceTracker } from "./DashboardResourceTracker";
import { TemplateDashboardResourceNotes } from "./DashboardResourceNotes";

import IconCamera from "~/../assets/icons/icon-camera.svg";

import IconNavigationStories from "~/../assets/icons/icon-navigation-stories.svg";
import IconNavigationArticles from "~/../assets/icons/icon-navigation-articles.svg";
import IconNavigationTracker from "~/../assets/icons/icon-navigation-tracker.svg";
import IconNavigationNotes from "~/../assets/icons/icon-navigation-notes.svg";

import IconDashboardResourcesChip from "~/../assets/icons/dashboard-resources-chip.svg";
import useGetMetastaticBreastCancerDiagnosisId from "~/hooks/useGetMetastaticBreastCancerDiagnosisId";

const StyledUserHeaderWrapper = styled.div(() => [tw`-mx-4 overflow-hidden`]);

const StyledUserHeaderImage = styled.div(
  (props: { backgroundImage?: string }) => [
    tw`bg-pink-medium bg-cover bg-center`,
    css`
      width: calc(100% + 48px);
      height: 120px;
      margin: 0px -24px;
      border-bottom-left-radius: 60%;
      border-bottom-right-radius: 60%;
      background-image: url(${props.backgroundImage});
    `,
  ]
);

const StyledUserProfileImage = styled.div(
  (props: { backgroundImage?: string }) => [
    tw`w-full rounded-full mx-auto -mt-12 mb-4 bg-blue-navy border border-white border-6 text-center cursor-pointer`,
    tw`flex items-center justify-center content-center flex-wrap bg-cover bg-center`,
    css`
      height: 110px;
      width: 110px;
      background-image: url(${props.backgroundImage});
    `,
  ]
);

const StyledUserProfileIcon = styled.div(() => [tw`mb-og1`]);

const StyledResourceNavigation = styled.div(() => [
  tw`grid grid-cols-2 gap-2 mb-og10`,
]);

const StyledResourceItem = styled.div((props: { active?: boolean }) => [
  tw`flex flex-col items-center border border-pink-dusty rounded-sm shadow py-3 cursor-pointer`,
  props.active ? tw`bg-pink text-white` : tw`bg-white text-blue-navy`,
]);

const StyledResourceLabel = styled.div();

const StyledDashboardResourceContentWrapper = styled.div(() => [
  tw`-mx-og4 px-4 md:-mx-og12 md:px-og12 pt-12 pb-8 mb-8 bg-pink-medium overflow-hidden relative`,
]);

const StyledDashboardResourceContentShadow = styled.div(() => [
  css`
    position: absolute;
    top: 0;
    left: 2.5%;
    width: 95%;
    height: 0px;
    box-shadow: 0px 0px 33px 3px rgb(98, 45, 45);
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 45%;
      width: 10%;
      height: 0px;
      box-shadow: 0px 0px 33px 3px rgb(98, 45, 45);
      z-index: 1;
    }
  `,
]);

const StyledDashboardResourceChipWrapper = styled.div(() => [
  css`
    position: absolute;
    top: -32px;
    left: calc(50% - 32px);
    z-index: 2;
  `,
]);

export const TemplateDashboard: React.FunctionComponent<
  TemplateDashboardProps
> = () => {
  Hooks.useAuthentication();
  const patientContext = React.useContext(Providers.PatientContext);
  const modalContext = React.useContext(Providers.ModalContext);
  const diagnosisHook = Hooks.useDiagnosis();

  const { metastaticDiagnosisId } = useGetMetastaticBreastCancerDiagnosisId();

  const [dashboardResourceActive, setDashboardResourceActive] = React.useState<
    DashboardResource | undefined
  >();

  const timelineData =
    patientContext.diagnosis?.id === metastaticDiagnosisId
      ? diagnosisHook.treatmentData
      : diagnosisHook.situationData;
  const timelineValue =
    patientContext.diagnosis?.id === metastaticDiagnosisId
      ? patientContext.treatment?.id
      : patientContext.situation?.id;

  return (
    <Components.AppLayout
      headerVariation="navigationUser"
      footerVariation="dashboard"
    >
      <Components.Meta title="Dashboard" />
      <StyledUserHeaderWrapper>
        <StyledUserHeaderImage
          backgroundImage={patientContext.profileImageBackgroundContent ?? ""}
        />
        <StyledUserProfileImage
          backgroundImage={patientContext.profileImageUserContent ?? ""}
          onClick={() => modalContext.toggleModal("accountChooseLook")}
        >
          {!patientContext.profileImageUserContent && (
            <React.Fragment>
              <StyledUserProfileIcon>
                <IconCamera />
              </StyledUserProfileIcon>
              <Components.TextVariation variation="button3" color="white">
                {"Customise dashboard"}
              </Components.TextVariation>
            </React.Fragment>
          )}
        </StyledUserProfileImage>
      </StyledUserHeaderWrapper>
      <Components.Box spacing="og4">
        <Components.TextVariation
          variation="heading1"
          spacing="og1"
          align="center"
        >
          {`Hello ${patientContext.auth?.patient_details.first_name}`}
        </Components.TextVariation>
      </Components.Box>
      {patientContext.diagnosis && timelineData && (
        <Components.Box spacing="og4">
          <Components.Timeline
            data={timelineData}
            value={`${timelineValue}`}
            hideNavigation
          />
        </Components.Box>
      )}

      <Components.HorizontalRule />
      <Components.Box spacing="og4">
        <Components.TextVariation variation="paragraph1bold" spacing="og4">
          {
            "We've been listening to your feedback and we're excited to share that improvements are coming to My Journey in 2025. Our team have been working hard on designing an enhanced experience around your needs, including personalised content. Simply stay tuned for updates about the evolution of your My Journey experience."
          }
        </Components.TextVariation>
      </Components.Box>
      <Components.Box spacing="og4">
        <Components.TextVariation variation="heading1" spacing="og4">
          {"My resources"}
        </Components.TextVariation>
        <StyledResourceNavigation>
          {[
            {
              label: "Stories",
              resource: DashboardResource.STORIES,
              icon: <IconNavigationStories />,
            },
            {
              label: "Articles",
              resource: DashboardResource.ARTICLES,
              icon: <IconNavigationArticles />,
            },
            {
              label: "Symptom Tracker",
              resource: DashboardResource.TRACKER,
              icon: <IconNavigationTracker />,
            },
            {
              label: "Notes",
              resource: DashboardResource.NOTES,
              icon: <IconNavigationNotes />,
            },
          ].map((item) => (
            <StyledResourceItem
              key={item.resource}
              active={item.resource === dashboardResourceActive}
              onClick={() => setDashboardResourceActive(item.resource)}
            >
              {item.icon}
              <StyledResourceLabel>
                <Components.TextVariation
                  variation={
                    item.resource === dashboardResourceActive
                      ? "navigationbold"
                      : "navigation"
                  }
                  color={
                    item.resource === dashboardResourceActive
                      ? "white"
                      : "blueNavy"
                  }
                >
                  {item.label}
                </Components.TextVariation>
              </StyledResourceLabel>
            </StyledResourceItem>
          ))}
        </StyledResourceNavigation>
      </Components.Box>
      {dashboardResourceActive ? (
        <StyledDashboardResourceContentWrapper>
          <StyledDashboardResourceChipWrapper>
            <IconDashboardResourcesChip width="64px" height="64px" />
          </StyledDashboardResourceChipWrapper>
          <StyledDashboardResourceContentShadow />
          {dashboardResourceContent[dashboardResourceActive]}
        </StyledDashboardResourceContentWrapper>
      ) : (
        <Components.HorizontalRule />
      )}
    </Components.AppLayout>
  );
};

export type TemplateDashboardProps = unknown;

enum DashboardResource {
  STORIES = "stories",
  ARTICLES = "articles",
  TRACKER = "tracker",
  NOTES = "notes",
}

const dashboardResourceContent: {
  [key in DashboardResource]: JSX.Element;
} = {
  [DashboardResource.STORIES]: <TemplateDashboardResourceStories />,
  [DashboardResource.ARTICLES]: <TemplateDashboardResourceArticles />,
  [DashboardResource.TRACKER]: <TemplateDashboardResourceTracker />,
  [DashboardResource.NOTES]: <TemplateDashboardResourceNotes />,
};
